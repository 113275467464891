import { statusCodeToText } from "../utils/statusCodeToText";

const ViewOrder = ({ order }) => {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {Object.keys(order).map((key, index) => (
        <div key={index} className="bg-white shadow-md rounded-lg p-4">
          <h2 className="font-semibold text-lg mb-2">{key}</h2>
          <p className="text-gray-700 break-words">
            {key === "orderStatus"
              ? statusCodeToText(order[key]).title
              : order[key]}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ViewOrder;
