import { ref, remove } from "firebase/database";
import { database } from "./firebase";

const deleteById = (path, recordId) => {
  return new Promise((resolve, reject) => {
    const dbRef = ref(database, `${path}/${recordId}`);

    // Remove the record by setting its value to null
    remove(dbRef)
      .then(() => {
        resolve(true); // Resolve the promise when the delete operation is successful
      })
      .catch((error) => {
        reject(error); // Reject the promise if there's an error
      });
  });
};

export default deleteById;
