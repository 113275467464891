import { useState, useEffect } from "react";
import { createContext, useContext } from "react";
import { auth } from "./firebase/firebase";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import Orders from "./pages/Orders";
import NavBar from "./components/NavBar";
import Spinner from "./components/Spinner";
import "react-toastify/dist/ReactToastify.css";
import Drivers from "./pages/Drivers";
import SideBar from "./components/SideBar";
import { ToastContainer, toast } from "react-toastify";
import Lost from "./components/Lost";
import Login from "./pages/Login";
import getById from "./firebase/getById";
import OrdersRequests from "./pages/OrdersRequests";
import getRecords from "./firebase/getRecords";
import { notificationService } from "./firebase/notification";

const AppContext = createContext();
export default function App() {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sideBarVisibility, setSideBarVisibility] = useState(false);

  const [orders, setOrders] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [orderPayments, setOrderPayments] = useState([]);
  const [shippingParcels, setShippingParcels] = useState([]);

  const fetchOrders = async () => {
    try {
      const res = await getRecords("drivers");
      res &&
        setDrivers(res.map((driver) => ({ ...driver.data, key: driver.key })));
      setIsLoading(false);
    } catch (error) {
      toast("Something went wrong");
    }
  };
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isAdmin = await getById("admin", user.uid);
        if (isAdmin) {
          setAuthUser(user);
          authUser !== null && fetchOrders();
          setIsLoading(false);
          notificationService(user?.auth?.currentUser);
        }
      } else {
        setAuthUser(null);
        setIsLoading(false);
      }
    });
  }, [authUser]);

  if (isLoading)
    return (
      <div className="w-full h-[100vh] flex-col flex items-center justify-center">
        <Spinner />
        Welcome to Rapid Go
      </div>
    );
  return (
    <div className={`flex ${sideBarVisibility && "overflow-hidden"}`}>
      <AppContext.Provider
        value={{
          orders,
          setOrders,
          drivers,
          setDrivers,
          authUser,
          setAuthUser,
          orderPayments,
          setOrderPayments,
          shippingParcels,
          setShippingParcels,
          sideBarVisibility,
          setSideBarVisibility,
        }}
      >
        <BrowserRouter>
          {authUser && <SideBar />}
          <div className="w-full">
            {authUser && <NavBar />}
            <div className="p-2 sm:p-5">
              <Routes>
                {authUser ? (
                  <>
                    <Route path="/" element={<Orders />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/drivers" element={<Drivers />} />
                    <Route
                      path="/order-requests"
                      element={<OrdersRequests />}
                    />
                    <Route path="/*" element={<Lost />} />
                  </>
                ) : (
                  <Route path="/*" element={<Login />} />
                )}
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </AppContext.Provider>
      <ToastContainer pauseOnHover={true} />
    </div>
  );
}

// Custom hook to access the context
export const useAppContext = () => {
  return useContext(AppContext);
};
