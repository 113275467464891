import { Fragment, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useAppContext } from "../App";
import logo from "../assets/logo.jpg";
import { PiUserCircleLight } from "react-icons/pi";
import { PiSidebarSimpleFill, PiSidebarSimpleLight } from "react-icons/pi";
import { BiLogOut } from "react-icons/bi";
import { LiaUserCogSolid } from "react-icons/lia";
import Modal from "./Modal";
import ProfileForm from "./ProfileForm";
import { Dialog, Transition } from "@headlessui/react";

const NavBar = () => {
  const { sideBarVisibility, setSideBarVisibility } = useAppContext();
  const [toggle, setToggle] = useState(false);
  const { authUser, setAuthUser } = useAppContext();
  return (
    <nav className={`bg-white shadow sticky top-0`}>
      <div className="mx-auto w-full px-5 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
            <div
              className="flex flex-shrink-0 items-center cursor-pointer"
              onClick={() =>
                setSideBarVisibility((sideBarVisibility) => !sideBarVisibility)
              }
            >
              {sideBarVisibility ? (
                <PiSidebarSimpleLight size="25px" />
              ) : (
                <PiSidebarSimpleFill size="25px" />
              )}
              <img
                src={logo}
                className="ml-5 brand h-[20px]"
                alt="RapidGo Logo "
              />
            </div>
          </div>

          <div className="relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* <!-- Profile dropdown --> */}
            <div className="relative ml-3">
              <div>
                <button
                  type="button"
                  className="flex rounded-full text-sm focus:outline-none"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={() => setToggle((toggle) => !toggle)}
                >
                  <PiUserCircleLight color="#000" size="25px" />
                </button>
                <Transition.Root
                  show={toggle}
                  as={Fragment}
                  className=" bg-white absolute top-[40px] right-[20px] mt-2 w-48 origin-top-right rounded-md bg-000 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <Dialog
                    as="div"
                    className="z-10"
                    initialFocus={null}
                    onClose={setToggle}
                  >
                    <div
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                    >
                      {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                      <h3
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 font-bold"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-1"
                      >
                        {authUser.displayName || authUser.email}
                      </h3>
                      <span className="flex items-center px-4 py-2 gap-5">
                        <Modal
                          title="Edit Profile"
                          button={<LiaUserCogSolid size="20px" />}
                        >
                          <ProfileForm />
                        </Modal>
                        <BiLogOut
                          size="20px"
                          className="cursor-pointer"
                          onClick={() =>
                            signOut(auth).then(() => setAuthUser(null))
                          }
                        />
                      </span>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
