import React, { useEffect, useRef, useState } from "react";
import getRecords from "../firebase/getRecords";
import { toast } from "react-toastify";
import { colors } from "../constants";
import Spinner from "./Spinner";
import updateRecord from "../firebase/updateRecord";

const EditCost = ({ setOpen }) => {
  const [costs, setCosts] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const param1Ref = useRef(null);
  const param2Ref = useRef(null);

  const updateParams = async (event) => {
    event.preventDefault();
    if (param1Ref.current.value === "" || param2Ref.current.value === "") {
      toast("Please enter all values");
      return;
    }
    updateRecord("cost", null, {
      paramter1: parseFloat(param1Ref.current.value),
      paramter2: parseFloat(param2Ref.current.value),
    }).then(() => {
      getCosts();
      toast("Parameters Updated");
      setOpen(false);
    });
  };

  const getCosts = async () => {
    const res = await getRecords("cost");
    setCosts(res);
    setLoading(false);
  };

  useEffect(() => {
    getCosts();
  }, []);
  if (isLoading || costs === null) {
    return (
      <div className="w-100 h-100 flex flex-col items-center justify-center">
        <Spinner />
        <span className="mt-10">Loading Costs</span>
      </div>
    );
  }
  return (
    <div className=" sm:mx-auto sm:w-full sm:max-w-sm w-full relative md:w-[60vw]">
      <form className="space-y-3 flex flex-col items-center">
        {/* Name field */}
        <div className="w-full">
          <label
            htmlFor="param1"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Parameter 1
          </label>
          <div className="mt-2 space-y-2">
            <input
              id="param1"
              name="param1"
              type="number"
              min={0}
              ref={param1Ref}
              defaultValue={(costs && costs[0].data) || 0}
              className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Password field */}
        <div className="w-full">
          <label
            htmlFor="param2"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Parameter 2
          </label>
          <div className="mt-2 relative space-y-2">
            <input
              id="param2"
              name="param2"
              type="number"
              min={0}
              defaultValue={(costs && costs[1].data) || 0}
              ref={param2Ref}
              className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <button
              onClick={updateParams}
              type="submit"
              className={`flex w-full justify-center rounded-md bg-[${colors.accent}] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              Update Parameter 2
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCost;
