import { ref, onValue } from "firebase/database";
import { database } from "./firebase";

const getById = (path, recordId) => {
  return new Promise((resolve, reject) => {
    const dbRef = ref(database, `${path}/${recordId}`);

    onValue(
      dbRef,
      (snapshot) => {
        if (snapshot.exists()) {
          // The record exists
          const key = snapshot.key;
          const data = snapshot.val();
          resolve({ key, data });
        } else {
          // The record does not exist
          resolve(null);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export default getById;
