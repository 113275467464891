import { useEffect, useState } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import { mapAPI } from "../constants";
import getById from "../firebase/getById";
import Spinner from "./Spinner";

const MapWithRoute = ({ order }) => {
  const source = {
    lat: order.picklat,
    lng: order.picklng,
  };
  const destination = {
    lat: order.deliveryLat,
    lng: order.deliveryLng,
  };

  const [currentLocation, setCurrentLocation] = useState(null);
  const [directions, setDirections] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const mapStyles = {
    height: "500vh",
    width: "80vw",
  };

  const getDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();

    // Define the directions request
    const directionsRequest = {
      origin: new window.google.maps.LatLng(source.lat, source.lng),
      destination: new window.google.maps.LatLng(
        destination.lat,
        destination.lng
      ),
      travelMode: "DRIVING", // You can change the travel mode as needed
    };

    // Request directions
    directionsService.route(directionsRequest, (result, status) => {
      if (status === "OK") {
        setDirections(result);
      } else {
        console.error("Directions request failed:", status);
      }
    });
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapAPI,
    libraries: ["geometry", "drawing"],
  });

  useEffect(() => {
    const fetchCurrent = async () => {
      const res = await getById("shippingParcels", order.orderNumber);
      if (res.data && res.data.currentLat !== -1) {
        setCurrentLocation({
          lat: res.data.currentLat,
          lng: res.data.currentLng,
        });
      }
      setIsLoading(false);
    };
    fetchCurrent();
    // return () => {
    //   getUpdateMessage(
    //     "shippingParcels",
    //     order.orderNumber,
    //     setCurrentLocation
    //   );
    //};
  }, [order, setCurrentLocation]);
  if (!isLoaded || isLoading) {
    return (
      <div className="w-full flex flex-col items-center">
        <Spinner />
        Loading Coordinates
      </div>
    );
  }
  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={currentLocation || source}
        zoom={5}
        onLoad={getDirections}
      >
        {directions && !currentLocation && (
          <DirectionsRenderer directions={directions} />
        )}
        {currentLocation && <Marker position={currentLocation} />}
      </GoogleMap>
    </div>
  );
};

export default MapWithRoute;
