import { BiCheck, BiSolidTruck } from "react-icons/bi";
import { useAppContext } from "../App";
import { colors } from "../constants";
import { statusCodeToText } from "../utils/statusCodeToText";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import getById from "../firebase/getById";
import updateRecord from "../firebase/updateRecord";
import { sendNotification } from "../firebase/sendNotification";

const EditConfirmedOrder = ({ order, setOpen }) => {
  const { orders, drivers, setOrders } = useAppContext();
  const [parcel, setParcel] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(order.orderStatus);
  const [driverIndex, setDriverIndex] = useState(-1);
  const costRef = useRef(null);
  const status = statusCodeToText(order.orderStatus);

  const getParcel = async () => {
    try {
      let res = await getById("shippingParcels", order.key);
      setParcel({ ...res.data, key: res.key });
      if (res) res = { ...res.data, key: res.key };
      drivers.map((d, i) => d?.phone === res?.driverPhone && setDriverIndex(i));
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (event) => {
    console.log(parcel);
    event.preventDefault();
    if (statusCodeToText(selectedStatus).driver && driverIndex === -1) {
      toast("Please select a driver first");
      return;
    }
    let res = await updateRecord("orders", order.key, {
      orderStatus: selectedStatus,
    });
    res &&
      setOrders(
        orders.map((o) =>
          o.key === order.key ? { ...order, orderStatus: selectedStatus } : o
        )
      );
    let data = { orderModel: { ...order, orderStatus: selectedStatus } };
    if (selectedStatus === 1) {
      data = {
        currentLat: -1,
        currentLng: -1,
        driverName: drivers[driverIndex].name,
        driverPhone: drivers[driverIndex].phone,
        orderModel: { ...order, orderStatus: selectedStatus },
        driverCost: parseFloat(costRef.current.value),
        startTrip: false,
      };
    }
    res = await updateRecord("shippingParcels", order.key, data);
    if (res) {
      const notiData = {
        title: "Your Order was updated!",
        body: `You order ${order.key} was updated to ${
          statusCodeToText(selectedStatus).title
        }`,
      };
      toast(
        `Order ${order.key} was updated to ${
          statusCodeToText(selectedStatus).title
        }`
      );
      sendNotification(notiData, order.userId);
      setOpen(false);
    } else toast("Something went wrong try refreshing.");
    await getParcel();
  };

  useEffect(() => {
    getParcel();
  }, [drivers, order]);
  return (
    <form onSubmit={handleSubmit} className="w-[80vw]  text-left">
      <div className="flex flex-col gap-2 w-full">
        <h1 className="font-bold">
          Order Status ({statusCodeToText(order.orderStatus).title})
        </h1>
        <h1 className="font-bold w-full">Order Status</h1>

        {[0, 1, 2, -1, -2].map(
          (s) =>
            (s === status?.to[0] || s === status?.to[1]) && (
              <label className="space-x-2" key={s}>
                <input
                  type="radio"
                  name="orderStatus"
                  defaultChecked={order.orderStatus === s}
                  value={s}
                  required
                  onChange={() => setSelectedStatus(s)}
                />
                <span>{statusCodeToText(s).title}</span>
              </label>
            )
        )}
      </div>

      {statusCodeToText(order.orderStatus).driver && (
        <div className="mt-3">
          <div className="flex items-center gap-2 mb-2">
            <span className="w-[100px]">Driver ($) %</span>
            <input
              type="number"
              placeholder="Amount"
              name="driverCost"
              defaultValue={parcel.driverCost}
              required
              className="ring-1 rounded p-2 ring-slate-200 w-[50%]"
              ref={costRef}
            />
          </div>
          <div className="flex flex-wrap gap-3 ">
            {drivers.map((d, i) => (
              <div
                className="flex items-center gap-3 w-full md:w-[48%] lg:w-[32%] bg-slate-300 p-3"
                key={d.key}
                onClick={() => setDriverIndex(i)}
              >
                <BiSolidTruck size={30} />
                <div>
                  <span className="font-bold block">{d.name}</span>
                  <span>{d?.phone}</span>
                </div>
                {i == driverIndex && <BiCheck size={20} className="ml-auto" />}
              </div>
            ))}
          </div>
        </div>
      )}
      <button
        type="submit"
        className={`flex w-full mt-2 justify-center rounded-md bg-[${colors.accent}] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
      >
        Update
      </button>
    </form>
  );
};

export default EditConfirmedOrder;
