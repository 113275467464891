import { BiExpand, BiPen, BiTrash } from "react-icons/bi";
import { colors } from "../constants";
import Modal from "./Modal";
import ViewOrder from "./ViewOrder";
import MapWithRoute from "./MapWithRoute";
import { FaRoute } from "react-icons/fa6";
import { statusCodeToText } from "../utils/statusCodeToText";
import EditConfirmedOrder from "./EditOrderStatus";
import deleteById from "../firebase/deleteById";
import { toast } from "react-toastify";
import { useAppContext } from "../App";

const OrderCard = ({ order }) => {
  const { title, color } = statusCodeToText(order.orderStatus);
  const { orders, setOrders } = useAppContext();
  const handleDelete = async () => {
    const answer = window.confirm("Are you sure?");
    if (answer) {
      try {
        const res = await deleteById("orders", order.key);
        setOrders(orders.filter((o) => o.key !== order.key));
        res && toast("Deleted Successully");
      } catch (err) {
        toast("Something Went Wrong");
      }
    }
  };
  return (
    <div className="bg-slate-200 rounded-lg shadow-lg overflow-hidden relative w-full md:w-[48%] lg:w-[32%]">
      <div className="flex min-h-[100px]">
        <div
          className={`w-30 bg-[${colors.accent}] text-white p-2 md:p-3 lg:p-4 rounded-l-lg flex flex-col justify-between items-center`}
        >
          <p className="text-sm font-semibold">
            {new Date(order.createDate).toLocaleString("en-US", {
              month: "short",
              day: "2-digit",
            })}
          </p>
          <p className="text-lg mt-2">
            {new Date(order.createDate).toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}
          </p>
        </div>

        <div className="flex-grow p-2">
          <ul className="list-none flex flex-col">
            <li className="break-words text-sm">
              <b>Order No:</b> {order.orderNumber}
            </li>
            <span className="hidden bg-green-200"></span>
            <span className="hidden bg-red-200"></span>
            <li className="break-words text-sm">
              <b>Order Comment:</b> {order?.notes}
            </li>
            <li className="break-words text-sm">
              <b>Status: </b>
              <span className={`text-${color}-500`}>{title}</span>
            </li>
            <li className="break-words text-sm">
              <b>Payment Status:</b>
              {order.paymentStatus}
            </li>
          </ul>

          <div className="mt-2 flex items-center gap-5 px-2 ">
            {/* <Modal title="Edit Order" button={<BiEdit />}>
                <EditOrder order={order} />
              </Modal> */}
            <Modal title="Order Details" button={<BiExpand size={20} />}>
              <ViewOrder order={order} />
            </Modal>
            <Modal title="Directions" button={<FaRoute size={18} />}>
              <MapWithRoute order={order} />
            </Modal>
            <Modal title="Update Cost" button={<BiPen size={20} />}>
              <EditConfirmedOrder order={order} />
            </Modal>
            <BiTrash
              className="cursor-pointer"
              size={20}
              onClick={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
