import { useAppContext } from "../App";
import DriversList from "../components/DriversList";
import ListingPost from "../components/ListingPost";

const Drivers = () => {
  document.title = "Drives - Rapid Go";
  const { drivers } = useAppContext();
  return (
    <ListingPost title="Drivers" count={drivers.length}>
      <DriversList />
    </ListingPost>
  );
};

export default Drivers;
