import { useEffect, useState } from "react";
import getRecords from "../firebase/getRecords";
import { useAppContext } from "../App";
import Spinner from "./Spinner";
import Alert from "./Alert";
import { toast } from "react-toastify";
import OrderCard from "./OrderCard";

const OrdersList = ({ search, refresh, filterBy }) => {
  const { orders, setOrders } = useAppContext();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRecords("orders");
        res && setOrders(res.map((order) => order.data));
        setLoading(false);
      } catch (error) {
        toast("Something went wrong");
      }
    };
    fetchData();
  }, [setOrders, refresh]);

  if (isLoading)
    return (
      <div className="w-full flex flex-col items-center">
        <Spinner />
        Loading Orders
      </div>
    );
  else if (
    (filterBy
      ? orders.filter((o) => o.orderStatus === parseInt(filterBy))
      : orders
    ).length === 0
  )
    return (
      <Alert title="No Orders Found" color="red">
        If there's any order in the database, it will start showing here.
      </Alert>
    );
  return (
    <div className="flex-col overflow-x-hidden">
      <div className="flex flex-wrap gap-2">
        {(filterBy
          ? orders.filter((o) => o.orderStatus === parseInt(filterBy))
          : orders
        ).map(
          (order, index) =>
            order.orderNumber?.includes(search) && (
              <OrderCard order={order} key={index} />
            )
        )}
      </div>
    </div>
  );
};

export default OrdersList;
