import { BsFillInboxFill } from "react-icons/bs";
import { TbBike } from "react-icons/tb";
import { BiSolidDollarCircle } from "react-icons/bi";
export const colors = {
  accent: "#006040",
};

export const site = {
  title: "RapidGo - Admin",
};

export const navLinks = [
  { title: "Orders", to: "/orders", icon: <BsFillInboxFill /> },
  { title: "Drivers", to: "/drivers", icon: <TbBike /> },
  {
    title: "Order Requests",
    to: "/order-requests",
    icon: <BiSolidDollarCircle />,
  },
];

export const mapAPI = "AIzaSyC-SF1nSz46OLwe2jhsd4nO2RxX6JqmWak";
