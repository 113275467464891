import OrdersList from "../components/OrdersList";
import { useAppContext } from "../App";
import { BiRefresh } from "react-icons/bi";
import { toast } from "react-toastify";
import { useRef, useState } from "react";
import { statusCodeToText } from "../utils/statusCodeToText";

const Orders = () => {
  document.title = "Orders - Rapid Go";
  const { orders } = useAppContext();
  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [filter, setfilter] = useState(null);
  const [refresh, setRefresh] = useState();
  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row items-center justify-between p-3 gap-3">
        <h2 className="text-2xl font-bold">{`${
          filter ? statusCodeToText(parseInt(filter)).title : "Orders"
        } (${
          (filter
            ? orders.filter((o) => o.orderStatus === parseInt(filter))
            : orders
          ).length
        })`}</h2>
        <div className="flex flex-col md:flex-row gap-2 items-center">
          <input
            type="text"
            id="searchInput"
            className="mt-1 block px-2 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
          focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
          disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
          invalid:border-pink-500 invalid:text-pink-600 w-60"
            ref={searchRef}
            placeholder="Search by ID..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div className="flex items-center justify-center w-full md:w-auto">
            <select
              name="filter"
              onChange={(e) => setfilter(e.target.value)}
              className="w-full md:w-auto mt-1 block px-2 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
          focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
          disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            >
              <option value="">All</option>
              <option value="0">Placed</option>
              <option value="1">Shipping</option>
              <option value="2">Shipped</option>
              <option value="-1">Cancel</option>
            </select>
            <BiRefresh
              className="ml-3 border-slate-300 border rounded-md p-1"
              size="35px"
              onClick={() => {
                setRefresh(Math.random);
                toast("Orders Refreshed");
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full p-3">
        <OrdersList search={search} refresh={refresh} filterBy={filter} />
      </div>
    </div>
  );
};

export default Orders;
