import { toast } from "react-toastify";
import { useAppContext } from "../App";
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from "xlsx";
const ExportOrders = () => {
  const { orders } = useAppContext();

  const exportToExcel = () => {
    const fileName = `Orders Report - Rapid Go.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(orders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
    toast("Order report exported Sucessfully");
  };
  if (orders.length === 0) return null;
  return (
    <div
      className="flex items-center gap-2 w-full my-3"
      onClick={exportToExcel}
    >
      <SiMicrosoftexcel size="15px" /> Export Orders
    </div>
  );
};

export default ExportOrders;
