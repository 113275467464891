import { useState } from "react";
import { colors } from "../constants";
import Spinner from "./Spinner";
import uploadFile from "../firebase/uploadFile";
import { toast } from "react-toastify";
import { sendNotification } from "../firebase/sendNotification";

const AdvertisementForm = ({ setOpen }) => {
  const [formData, setFormData] = useState({});
  const [imagType, setImageType] = useState({ image: "none" });
  const [isDone, setDone] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleChange = (event) => {
    if (event.target.type === "file") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.files[0],
      });
    } else
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formData.image === "none") formData.image = null;
    else if (formData.image === "link") formData.image = formData.imgLink;
    else if (formData.image === "upload") {
      const url = await uploadFile(formData.upload, "advertisment");
      if (url) {
        formData.image = url;
      }
    }
    try {
      const res = await sendNotification(formData, null, "/topics/customer");
      if (res) {
        toast("Sucessful sent to customers topic");
        setDone("Sucessful sent to customers topic");
        setOpen(false);
      }
    } catch (err) {
      toast("Error sending message");
    }

    setLoading(false);
  };

  if (isDone.length > 0)
    return (
      <h2 className="text-xl 2-full text-centerfont-medium mb-2">{isDone}</h2>
    );
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label
          htmlFor="title"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Title
        </label>
        <input
          id="title"
          name="title"
          type="text"
          required
          className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={handleChange}
        />
      </div>

      <div className="mt-2">
        <label
          htmlFor="content"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Content
        </label>
        <input
          id="content"
          name="content"
          type="text"
          required
          className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={handleChange}
        />
      </div>

      <div className="mt-2">
        <label
          htmlFor="website"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Website URL
        </label>
        <input
          id="website"
          name="website"
          type="url"
          required
          className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={handleChange}
        />
      </div>

      <div className="mt-2 space-x-3">
        <h2 className="text-xl font-medium mb-2">Attach Image</h2>
        <label htmlFor="image" className="text-sm font-medium">
          <input
            id="image"
            name="image"
            type="radio"
            className="inline-block mr-2"
            value="none"
            required
            onChange={(e) => {
              setImageType(null);
              handleChange(e);
            }}
          />
          None
        </label>
        <label htmlFor="image" className="text-sm font-medium">
          <input
            id="image"
            name="image"
            type="radio"
            value="link"
            required
            className="inline-block mr-2"
            onChange={(e) => {
              setImageType("link");
              handleChange(e);
            }}
          />
          Image Link
        </label>
        <label htmlFor="image" className="text-sm font-medium">
          <input
            id="image"
            name="image"
            type="radio"
            value="upload"
            required
            className="inline-block mr-2"
            onChange={(e) => {
              setImageType("upload");
              handleChange(e);
            }}
          />
          Uplaod
        </label>
      </div>
      <div className="mt-3">
        {imagType === "link" && (
          <input
            id="imgLink"
            name="imgLink"
            type="url"
            placeholder="Image link"
            required
            className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={handleChange}
          />
        )}
        {imagType === "upload" && (
          <input
            id="upload"
            name="upload"
            type="file"
            accept="image/*"
            required
            className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={handleChange}
          />
        )}
      </div>
      <button
        type="submit"
        className={`flex w-full justify-center rounded-md bg-[${colors.accent}] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mt-3`}
      >
        Publish Advertisement
      </button>

      {isLoading && (
        <div className="w-full flex flex-col gap-2 items-center left-0 justify-center absolute h-full top-0 bg-[#ffffffaa]">
          <Spinner />
          <h2>Publishing...</h2>
        </div>
      )}
    </form>
  );
};

export default AdvertisementForm;
