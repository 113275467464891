import { useRef } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import logo from "../assets/logo.jpg";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { colors } from "../constants";
import { toast } from "react-toastify";
import getById from "../firebase/getById";
import { useAppContext } from "../App";
import Spinner from "./Spinner";
const LoginForm = () => {
  const navigate = useNavigate();
  const [passVisibility, setPassVisibility] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { setAuthUser } = useAppContext();
  const [error, setError] = useState("");
  const emailRef = useRef(null);
  const passRef = useRef(null);

  const login = (event) => {
    event.preventDefault();
    setLoading(true);
    const email = emailRef.current?.value || "";
    const pass = passRef.current?.value || "";
    signInWithEmailAndPassword(auth, email, pass)
      .then(async (userCredentials) => {
        if (userCredentials._tokenResponse.registered === true) {
          const isAdmin = await getById("admin", userCredentials.user.uid);
          if (isAdmin) {
            setAuthUser(userCredentials.user);
            navigate("/");
            toast("Welcome to Rapid Go");
          } else setError("User doesn't exist");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError("Invalid email or password");
        setLoading(false);
      });
  };

  return (
    <div className="flex min-h-full flex-col 1justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-10 w-auto" src={logo} alt="Your Company" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={login}>
          {error.length ? (
            <div
              className="flex rounded items-center bg-red-500 text-white text-sm font-bold px-4 py-3"
              role="alert"
            >
              <p>{error}</p>
            </div>
          ) : null}

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                ref={emailRef}
                id="email"
                name="email"
                type="email"
                required
                className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                {/* <Link
                  to="/reset"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </Link> */}
              </div>
            </div>
            <div className="mt-2 relative">
              <input
                ref={passRef}
                id="password"
                name="password"
                type={passVisibility ? "text" : "password"}
                required
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div
                className="absolute right-2 top-2.5 cursor-pointer"
                onClick={() =>
                  setPassVisibility((passVisibility) => !passVisibility)
                }
              >
                {passVisibility ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={`flex w-full justify-center rounded-md bg-[${colors.accent}] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
      {isLoading && (
        <div className="w-[100vw] h-[100vh] flex flex-col items-center justify-center absolute top-0 left-0 bg-[#ffffffaa]">
          <Spinner />
          <span className="mt-10">
            Hold tight, I'm trying to remember you : )
          </span>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
