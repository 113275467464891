import { Card, List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { auth } from "../firebase/firebase";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { PiSidebarSimpleLight } from "react-icons/pi";
import { navLinks } from "../constants";
import logo from "../assets/logo.jpg";
import { BiUser, BiLogOut, BiDollar, BiNotification } from "react-icons/bi";
import { useAppContext } from "../App";
import ExportOrders from "./ExportOrders";
import Modal from "./Modal";
import EditCost from "./EditCost";
import AdvertisementForm from "./AdvertisementForm";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
const SideBar = () => {
  const { authUser, setAuthUser, sideBarVisibility, setSideBarVisibility } =
    useAppContext();
  return (
    <Transition.Root
      show={sideBarVisibility}
      as={Fragment}
      className="fixed top-0 left-0 h-[100vh] w-full max-w-[20rem] bg-white"
    >
      <Dialog
        as="div"
        className="z-10"
        initialFocus={null}
        onClose={setSideBarVisibility}
      >
        <Card className="h-[100vh] w-full max-w-[20rem] bg-white p-4 pt-1 pl-2 shadow-xl z-10 shadow-blue-gray-900/5 fixed top-0 left-0">
          <div className="mb-2 flex items-center gap-4 p-4">
            <div
              className="flex flex-shrink-0 items-center cursor-pointer"
              onClick={() =>
                setSideBarVisibility((sideBarVisibility) => !sideBarVisibility)
              }
            >
              <PiSidebarSimpleLight size="25px" />
              <img
                src={logo}
                className="ml-5 brand h-[20px]"
                alt="RapidGo Logo "
              />
            </div>
          </div>
          <List>
            {navLinks.map((link) => (
              <Link
                to={link.to}
                key={link.to}
                onClick={() => setSideBarVisibility(false)}
              >
                <ListItem>
                  <ListItemPrefix className="mr-2">{link.icon}</ListItemPrefix>
                  {link.title}
                </ListItem>
              </Link>
            ))}
            <hr className="my-2 border-blue-gray-50" />
            <ListItem className="py-0">
              <ExportOrders />
            </ListItem>
            <ListItem className="py-2.5">
              <Modal title="Edit Cost" button={<BiDollar />} showTitle={true}>
                <EditCost />
              </Modal>
            </ListItem>
            <ListItem className="py-2.5">
              <Modal
                title="Create Advertisement"
                button={<BiNotification />}
                showTitle={true}
              >
                <AdvertisementForm />
              </Modal>
            </ListItem>
            <ListItem>
              <ListItemPrefix className="mr-2">
                <BiUser />
              </ListItemPrefix>
              {authUser.email}
            </ListItem>
            <ListItem
              onClick={() => signOut(auth).then(() => setAuthUser(null))}
            >
              <ListItemPrefix className="mr-2">
                <BiLogOut />
              </ListItemPrefix>
              Log Out
            </ListItem>
          </List>
        </Card>
      </Dialog>
    </Transition.Root>
  );
};

export default SideBar;
