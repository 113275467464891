import { BiDollar } from "react-icons/bi";
import { colors } from "../constants";
import Modal from "./Modal";
import UpdateCost from "./UpdateCost";

const OrderRequestCard = ({ order }) => {
  return (
    <div className="bg-gray-100 rounded-lg shadow-lg overflow-hidden relative w-full md:w-[48%] lg:w-[32%]">
      <div className="flex min-h-[100px]">
        <div
          className={`w-30 bg-[${colors.accent}] text-white p-4 rounded-l-lg flex flex-col justify-between items-center`}
        >
          <p className="text-sm font-semibold">
            {new Date(order.timeStamp).toLocaleString("en-US", {
              month: "short",
              day: "2-digit",
            })}
          </p>
          <p className="text-sm mt-2">
            {new Date(order.timeStamp).toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}
          </p>
        </div>

        <div className="flex-grow p-2">
          <ul className="list-none">
            <li className="break-words">{order.orderNumber}</li>
            <span className="hidden bg-green-200"></span>
            <span className="hidden bg-red-200"></span>
            <li className="text-sm">
              <b>Username: </b>
              <span>{order.userName || "N/A"}</span>
            </li>
            <li className="text-sm">
              <b>Pickup: </b>
              <span>{order.pickLocation}</span>
            </li>
            <li className="text-sm">
              <b>Destination: </b>
              <span>{order.destinationLocation}</span>
            </li>
            <li className="text-sm">
              <b>Distance: </b>
              <span>{order.distance_value}</span>
            </li>
            <li className="text-sm text-red-500">
              <b>Current Cost: </b>
              <span>{parseFloat(order.currentPrice).toFixed(2)}</span>
            </li>
          </ul>

          {/* <div className="mt-2 flex items-center gap-2 px-2 ">
              <Modal title="Edit Order" button={<BiEdit />}>
                <EditOrder order={parcel} />
              </Modal>
              <Modal title="Order Details" button={<BiExpand />}>
                <ViewOrder order={parcel} />
              </Modal>
              <Modal title="Directions" button={<FaRoute />}>
                <MapWithRoute order={parcel} />
              </Modal>
            </div> */}
        </div>
      </div>
      <div className="option-toggle absolute right-0 top-0 cursor-pointer bg-green-200">
        <Modal title="Update Cost" button={<BiDollar size={30} />}>
          <UpdateCost order={order} path="orderPayment" />
        </Modal>
      </div>
    </div>
  );
};

export default OrderRequestCard;
