import { useEffect, useState } from "react";
import getRecords from "../firebase/getRecords";
import { useAppContext } from "../App";
import Spinner from "./Spinner";
import Alert from "./Alert";
import DriverCard from "./DriverCard";
import { toast } from "react-toastify";

const DriversList = ({ search, refresh }) => {
  const { drivers, setDrivers } = useAppContext();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRecords("drivers");
        res &&
          setDrivers(res.map((driver) => ({ ...driver.data, id: driver.key })));
        setLoading(false);
      } catch (error) {
        toast("Something went wrong");
      }
    };

    fetchData();
  }, [setDrivers, refresh]);

  if (isLoading)
    return (
      <div className="w-full flex flex-col items-center">
        <Spinner />
        Loading Drivers
      </div>
    );
  else if (drivers.length === 0)
    return (
      <Alert title="No Drivers Found" color="red">
        If there's any drivers in the database, it will start showing here.
      </Alert>
    );
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {drivers.map(
        (driver, index) =>
          JSON.stringify(driver)
            .toLowerCase()
            .includes(search.toLowerCase()) && (
            <div key={index}>
              <DriverCard driver={driver} />
            </div>
          )
      )}
      <div></div>
    </div>
  );
};

export default DriversList;
