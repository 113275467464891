import { BsPaypal } from "react-icons/bs";
import { MdContentCopy, MdToggleOff, MdToggleOn } from "react-icons/md";
import { toast } from "react-toastify";
import updateRecord from "../firebase/updateRecord";
import { useAppContext } from "../App";
const DriverCard = ({ driver }) => {
  const { drivers, setDrivers } = useAppContext();
  const handleCopyToClipBoard = (text) => {
    // Copy the PayPal email to the clipboard
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast("Copied to clipbaord");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  const handleDriverToggle = async () => {
    const res = await updateRecord("drivers", driver.id, {
      active: !driver.active,
    });
    if (res === true) {
      toast("Status updated sucessfully");
      setDrivers(
        drivers.map((d) =>
          d.key === driver.key ? { ...d, active: !driver.active } : d
        )
      );
    } else toast("Somthing went wrong");
  };

  return (
    <div className="rounded-lg overflow-hidden shadow-lg h-full">
      <div className="px-6 py-4">
        <span
          className="flex items-center 
          }-200 rounded-full py-1 text-xs cursor-pointer"
          onClick={handleDriverToggle}
        >
          {driver.active ? (
            <MdToggleOn size="30px" className="text-green-500" />
          ) : (
            <MdToggleOff size="30px" className="text-red-500" />
          )}

          <span className="inline-block pl-[0.15rem] hover:cursor-pointer">
            {driver.active ? "Active" : "Inactive"}
          </span>
        </span>
        <div className="font-bold text-xl mb-2 capitalize">{driver.name}</div>
        <p className="text-gray-700 text-base">
          {driver.address}
          <br />
          <b>Email:</b> {driver.email}
          <br />
          <b>Phone:</b> {driver.phone}
        </p>
        <span
          className="bg-blue-200 inline-block items-baseline gap-1 rounded-full px-3 py-1 text-sm font-semibold text-blue-700 mt-2"
          onClick={() => handleCopyToClipBoard(driver.paypalEmail)}
        >
          <span className="inline-block">
            <BsPaypal />
          </span>{" "}
          <span>{driver.paypalEmail || "N/A"}</span>
          <span className="inline-block text-gray-500 ml-3">
            <MdContentCopy />
          </span>{" "}
        </span>
      </div>
      <div></div>
    </div>
  );
};

export default DriverCard;
