import { updateProfile, updatePassword, getAuth, signOut } from "firebase/auth";
import { useAppContext } from "../App";
import { useRef, useState } from "react";
import { colors } from "../constants";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { app, auth } from "../firebase/firebase";
import userSmileGIF from "../assets/user-smile.gif";
const ProfileForm = () => {
  const { authUser, setAuthUser } = useAppContext();
  const nameRef = useRef(null);
  const passRef = useRef(null);
  const [passVisibility, setPassVisibility] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [passChanged, setPassChanged] = useState(false);
  const updateName = async (event) => {
    event.preventDefault();
    if (nameRef.current.value.length > 0) {
      await updateProfile(authUser, {
        displayName: nameRef.current.value,
        photoURL: authUser.photoURL,
      });
      toast("Name Updated sucessfully");
      setAuthUser(getAuth(app).currentUser);
      setNameChanged(false);
    } else toast("Please enter a name first");
  };
  const updatePass = async (event) => {
    event.preventDefault();
    try {
      if (passRef.current.value?.length > 8) {
        updatePassword(authUser, passRef.current.value);
        passRef.current.value = "";
        toast("Password updated successfully, Please login again.");
        signOut(auth);
        setPassChanged(false);
      } else toast("Password must be 8 character or more");
    } catch (err) {
      toast(err);
    }
  };

  return (
    <div className=" sm:mx-auto sm:w-full sm:max-w-sm w-full">
      <form
        className="space-y-3 flex flex-col items-center"
        action="#"
        method="POST"
      >
        <img src={userSmileGIF} width="120px" alt="User Smile Gif" />
        <h2 className="text-center text-lg sm:text-2xl font-bold m-0">
          {authUser.email}
        </h2>
        {/* Name field */}
        <div className="w-full">
          <label
            htmlFor="displayName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Display Name
          </label>
          <div className="mt-2 space-y-2">
            <input
              id="displayName"
              name="displayName"
              type="text"
              ref={nameRef}
              defaultValue={authUser.displayName}
              onChange={() => setNameChanged(true)}
              className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <button
              onClick={updateName}
              type="submit"
              className={`flex w-full justify-center rounded-md bg-[${colors.accent}] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              disabled={!nameChanged}
            >
              Update Profile
            </button>
          </div>
        </div>

        {/* Password field */}
        <div className="w-full">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            New Password
          </label>
          <div className="mt-2 relative space-y-2">
            <input
              id="password"
              name="password"
              type={passVisibility ? "text" : "password"}
              ref={passRef}
              className="block w-full rounded-md border-0 py-1.5  px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={() => setPassChanged(true)}
            />
            <div
              className="absolute right-2 top-0.5 cursor-pointer"
              onClick={() =>
                setPassVisibility((passVisibility) => !passVisibility)
              }
            >
              {passVisibility ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
            <button
              onClick={updatePass}
              type="submit"
              className={`flex w-full justify-center rounded-md bg-[${colors.accent}] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              disabled={!passChanged}
            >
              Update Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
