import { useRef } from "react";
import { colors } from "../constants";
import updateRecord from "../firebase/updateRecord";
import { useAppContext } from "../App";
import { toast } from "react-toastify";
import { sendNotification } from "../firebase/sendNotification";

const UpdateCost = ({ order, setOpen }) => {
  const constRef = useRef(null);
  const { orderPayments, setOrderPayments } = useAppContext();
  const handleSubmit = async (event) => {
    console.log(parseFloat(constRef.current.value));
    event.preventDefault();
    try {
      const price = parseFloat(constRef.current.value);
      const res = await updateRecord("orderPayment", order.key, {
        finalPrice: price,
      });
      if (res) {
        setOrderPayments(
          orderPayments.map((o) =>
            o.key === order.key
              ? {
                  ...o,
                  finalPrice: price,
                }
              : o
          )
        );
        toast("Updated Successfully");
        const res = await sendNotification(
          {
            title: "Admin update final cost!",
            content: `Admin update final cost ${constRef.current.value}, you can continue...`,
          },
          order.phoneNum
        );
        if (res) {
          toast(`${res.success} Notifcation(s) sent`);
        }
      }
      setOpen(false);
    } catch (err) {
      toast("Somethig went wrong");
    }
  };
  return (
    <form onSubmit={handleSubmit} className="w-[100vw] md:[60vw]">
      <h1>Update Cost</h1>
      <input
        type="number"
        placeholder="Updated Cost..."
        ref={constRef}
        min={0}
        step="any"
        defaultValue={order.finalPrice || order?.currentPrice}
        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        required
      />
      <button
        type="submit"
        className={`mt-2 flex w-full justify-center rounded-md bg-[${colors.accent}] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
      >
        Update Cost
      </button>
    </form>
  );
};

export default UpdateCost;
