// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOTnIahBRqWDelqsPs0SGI84ivsFv3xuA",
  authDomain: "rapidgo-ca.firebaseapp.com",
  databaseURL: "https://rapidgo-ca.firebaseio.com",
  projectId: "rapidgo-ca",
  storageBucket: "rapidgo-ca.appspot.com",
  messagingSenderId: "361255902608",
  appId: "1:361255902608:web:a163c2886bbe00d2f9e715",
  measurementId: "G-6DW817EH8G",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const fireStore = getFirestore(app);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const messaging = getMessaging(app);
