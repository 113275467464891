export const statusCodeToText = (orderStatus) => {
  switch (orderStatus) {
    case 0:
      return { title: "Placed", color: "orange", to: [1, -1], driver: true };
    case 1:
      return { title: "Shipping", color: "green", to: [2, -1] };
    case 2:
      return { title: "Shipped", color: "blue", to: [2, -1] };
    case -1:
      return { title: "Cancelled", color: "red", to: [0, -2] };
    case -2:
      return { title: "Deleted", color: "red", to: [1, -1] };
    default:
      return { title: "Ukn", color: "red", to: [-2, -2] };
  }
};
