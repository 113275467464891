import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
import { database } from "./firebase";

const getRecords = (path, filterObject = null) => {
  return new Promise((resolve, reject) => {
    const dbRef = ref(database, path);
    let queryRef = dbRef;

    // If a filter object is provided, apply filters
    if (filterObject) {
      // Loop through the filter object and apply filters
      Object.entries(filterObject).forEach(([key, value]) => {
        queryRef = query(queryRef, orderByChild(key), equalTo(value));
      });
    }

    onValue(
      queryRef,
      (snapshot) => {
        const records = [];
        snapshot.forEach((child) => {
          const key = child.key;
          const data = child.val();
          records.push({ key, data });
        });
        resolve(records);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export default getRecords;
