import { ref, update } from "firebase/database";
import { database } from "./firebase";

// Function to update a specific record in the Realtime Database
const updateRecord = (path, recordId, newData) => {
  const dbRef = ref(database, `${path}${recordId ? "/" + recordId : ""}`);

  return new Promise((resolve, reject) => {
    update(dbRef, newData)
      .then((res) => {
        resolve(true);
      })
      .catch((error) => {
        console.error("Error updating record:", error);
        reject(false);
      });
  });
};

export default updateRecord;
