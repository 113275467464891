import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import updateRecord from "./updateRecord";

const vapidKey =
  "BD1WMqxa1-LHep3BG4szpwnJoEM9EkuS_VF-_vE8TAkGN_d6dXhFzVLDvBVLYMC3CcNZ3cL5LDLKgcfMFfjcwjg";

export const notificationService = async (currentUser) => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, { vapidKey });
    await updateRecord("tokens", currentUser.uid, { token });
    // const res = await messaging.subscribeToTopic("new_order");
    // console.log(res);
    subscribeTokenToTopic(token, "new_order");
  } else {
    window.alert(
      "You blocked the notification, please allow it receive notifications"
    );
  }
};

const serverKey =
  "AAAAVByHnZA:APA91bGFokIzPMDcJsyqEMmSViBL13QF9pQAzvo4nJ3Kj22qaU8XbbPrZ-GxeZCcmyTvn_NDrWEKbTQQMP5Rgd_L7Yt6GVlW9KjYw9CL0tai_r8LcOa0fSAdmuWCwSGLkkencQthMDWz";

const subscribeTokenToTopic = async (token, topic) => {
  fetch("https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic, {
    method: "POST",
    headers: new Headers({
      Authorization: `key=${serverKey}`,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw new Error(response.text());
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
