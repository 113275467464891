import React from "react";
import { colors } from "../constants";

const Lost = () => {
  return (
    <div class="flex justify-center items-center h-[80vh]">
      <div class="text-center">
        <h1 class="text-8xl mb-3 font-medium text-gray-300">404</h1>
        <p class="text-xl font-medium m-6">
          Sorry, the page you're looking for can't be found.
        </p>
        <a
          href="/"
          class={`bg-[${colors.accent}] text-white py-2 px-4 rounded`}
        >
          Go Home
        </a>
      </div>
    </div>
  );
};

export default Lost;
