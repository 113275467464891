import getById from "./getById";

export const sendNotification = async (message, key, topic) => {
  let token = [];
  if (key) {
    token = await getById("tokens", key);
    token = token.data.token;
  }
  // Define the URL
  const url = "https://fcm.googleapis.com/fcm/send";

  // Define the request headers
  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization:
      "key=AAAAVByHnZA:APA91bGFokIzPMDcJsyqEMmSViBL13QF9pQAzvo4nJ3Kj22qaU8XbbPrZ-GxeZCcmyTvn_NDrWEKbTQQMP5Rgd_L7Yt6GVlW9KjYw9CL0tai_r8LcOa0fSAdmuWCwSGLkkencQthMDWz",
  });

  // Define the data to be sent in the request body
  console.log(topic || token);
  const data = {
    to: topic || token,
    notification: {
      title: message.title,
      body: message.body || "",
      image: message.image || "",
    },
    data: {
      click_action: message.link || "",
      priority: "high",
      sound: "app_sound.wav",
      content_available: true,
      bodyText: "From Rapid Go Inc.",
      organization: "Rapid Go Inc.",
    },
  };

  // Wrap the fetch request in a promise
  return new Promise((resolve, reject) => {
    // Define the options for the fetch request
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };

    // Send the POST request
    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        resolve(responseData); // Resolve the promise with the response data
      })
      .catch((error) => {
        console.error("Error sending notification:", error);
        reject(error); // Reject the promise with the error
      });
  });
};
