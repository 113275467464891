import { useRef, useState } from "react";
import React from "react";
import { BiRefresh } from "react-icons/bi";
import { toast } from "react-toastify";

const ListingPost = ({ title, count, children }) => {
  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState();
  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row items-center justify-between p-3 gap-3">
        <h2 className="text-2xl font-bold">{`${title} (${count})`}</h2>
        <div className="flex items-center">
          <input
            type="text"
            id="searchInput"
            className="mt-1 block px-2 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
          focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
          disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
          invalid:border-pink-500 invalid:text-pink-600 w-60"
            ref={searchRef}
            placeholder="Search by ID..."
            onChange={() => {
              setSearch(searchRef.current.value);
            }}
          />
          <BiRefresh
            className="ml-3 border-slate-300 border rounded-md p-1"
            size="35px"
            onClick={() => {
              setRefresh(Math.random);
              toast("Refreshed");
            }}
          />
        </div>
      </div>
      <div className="w-full p-3">
        {React.cloneElement(children, { search, refresh })}
      </div>
    </div>
  );
};

export default ListingPost;
