import { useEffect, useRef, useState } from "react";
import getRecords from "../firebase/getRecords";
import { useAppContext } from "../App";
import { Spinner } from "@material-tailwind/react";
import Alert from "../components/Alert";
import { BiRefresh } from "react-icons/bi";
import { toast } from "react-toastify";
import OrderRequestCard from "../components/OrderRequestCard";

const OrdersRequests = () => {
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState();
  const [isLoading, setLoading] = useState(true);
  const { orderPayments, setOrderPayments } = useAppContext();
  const searchRef = useRef(null);
  useEffect(() => {
    const getOrderPayments = async () => {
      setLoading(true);
      let res = await getRecords("orderPayment");
      res = res.map((r) => ({ ...r.data, key: r.key }));
      setOrderPayments(res);
      setLoading(false);
    };
    getOrderPayments();
  }, [setOrderPayments, refresh]);
  if (isLoading)
    return (
      <div className="w-full flex flex-col items-center">
        <Spinner />
        Loading Order Requests
      </div>
    );
  else if (orderPayments.length === 0)
    return (
      <Alert title="No Orders Found" color="red">
        If there's any parcel in the database, it will start showing here.
      </Alert>
    );

  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row items-center justify-between p-3 gap-3">
        <h2 className="text-2xl font-bold">{`Order Requests (${orderPayments.length})`}</h2>
        <div className="flex flex-col md:flex-row gap-2 items-center">
          <input
            id="searchInput"
            type="text"
            className="mt-1 block px-2 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
      invalid:border-pink-500 invalid:text-pink-600 w-60"
            ref={searchRef}
            placeholder="Search by ID..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <BiRefresh
            className="ml-3 border-slate-300 border rounded-md p-1"
            size="35px"
            onClick={() => {
              setRefresh(Math.random);
              toast("Requests Refreshed");
            }}
          />
        </div>
      </div>
      <div className="w-full p-3 flex flex-col md:flex-row flex-wrap gap-2">
        {orderPayments.map(
          (o) =>
            o.key.includes(search) && <OrderRequestCard order={o} key={o.key} />
        )}
      </div>
    </div>
  );
};

export default OrdersRequests;
